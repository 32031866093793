import request from '@/utils/request'

// 查询用户收藏列表
export function listUserCollection(query) {
  return request({
    url: '/user/user/collection/list',
    method: 'get',
    params: query
  })
}

// 查询用户收藏详细
export function getUserCollection(id) {
  return request({
    url: '/user/user/collection/' + id,
    method: 'get'
  })
}

// 新增用户收藏
export function addUserCollection(data) {
  return request({
    url: '/user/user/collection',
    method: 'post',
    data: data
  })
}

// 修改用户收藏
export function updateUserCollection(data) {
  return request({
    url: '/user/user/collection',
    method: 'put',
    data: data
  })
}

// 删除用户收藏
export function delUserCollection(id) {
  return request({
    url: '/user/user/collection/' + id,
    method: 'delete'
  })
}
